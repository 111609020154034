<template>
    <form-view 
        v-slot="{ model, onSuccess, onBack }"
        title="Редактирование задания"
        update
        url-update="/task/update/:id"
        url-back="/task"
        action-one="task/one"
    >
        <task-form :model="model" @success="onSuccess" @back="onBack" />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import TaskForm from '@/components/forms/TaskForm.vue'
export default {
    name: 'TaskUpdate',
    components: { FormView, TaskForm }
}
</script>